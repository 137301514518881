import {documentToPlainTextString} from '@contentful/rich-text-plain-text-renderer';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import {BLOCKS, INLINES} from '@contentful/rich-text-types';
import * as React from 'react';
import {Helmet} from 'react-helmet';

import Footer from '@components/footer/footer';
import Layout from '@components/layouts/layout';
import Navigation from '@components/navigation/navigation';

import {useIntl} from 'gatsby-plugin-intl';
import {Col, Container, Image, Row} from 'react-bootstrap';

const AktualnosciSinglePage = ({pageContext}) => {
  const {locale} = useIntl();

  const news = pageContext.news;

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const {title, file} = node.data.target.fields;
        return <Image src={`https:${file.pl.url}`} alt={title} fluid rounded />;
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        );
      },
    },
  };

  const description = documentToPlainTextString(news.content[locale]).substring(0, 160);

  return (
    <Layout>
      <Helmet>
        <title>{news.title[locale]} – Aktualności – Studenckie Koła Naukowe AGH</title>
        <meta property="og:title" content={`${news.title[locale]} – Aktualności – Studenckie Koła Naukowe AGH`} />
        {news.image && <meta property="og:image" content={`https:${news.image}`} />}
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
      </Helmet>

      <Navigation customPagesNav={pageContext.customPagesNav} />
      <div className="news-single-page pt-5">
        <Container className="py-xl">
          <Row>
            <Col lg={10} xl={8}>
              <h1>{news.title[locale] || news.title.pl}</h1>
              <span>{news.date}</span>
              {news.image && <Image src={news.image} fluid rounded />}
              <div className="news-single-page__content">
                {documentToReactComponents(news.content[locale], options)}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </Layout>
  );
};

export default AktualnosciSinglePage;
